<script setup>
const config = useRuntimeConfig()
const recaptcha = useRecaptcha()

const state = reactive({
  loading: false,
  valid: false,
  email: null,
  success: false,
  error: false,
  errorMsg: null,
})

const form = ref()

async function submit() {
  form.value.validate()
  if (!state.valid)
    return

  state.loading = true
  try {
    // run recaptcha
    const token = await recaptcha('subscribe')

    await $fetch('/api/newsletter-subscribe', {
      method: 'POST',
      body: {
        recaptcha_token: token,
        email: state.email,
        listId: config.public.mailchimp.audienceId,
      },
    })

    state.success = true
    setTimeout(() => {
      state.success = false
    }, 5000)
  }
  catch (err) {
    console.error(err?.response)
    state.errorMsg = err?.response?._data?.message || 'Something went wrong. Please try again.'
    state.error = true
    setTimeout(() => {
      state.error = false
    }, 5000)
  }
  finally {
    state.loading = false
    form.value.reset()
  }
}
</script>

<template>
  <div>
    <v-form
      ref="form"
      v-model="state.valid"
      :disabled="state.loading"
      @submit.prevent="submit"
    >
      <div class="form mb-2">
        <v-text-field
          v-model="state.email"
          density="comfortable"
          :rules="[
            v => !!v || 'Required',
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid E-mail Address',
          ]"
          type="email"
          label="Email Address"
          hide-details="auto"
          outlined
        />
        <v-btn
          large
          color="primary"
          style="margin-top: 5px"
          :disabled="state.loading"
          :loading="state.loading"
          type="submit"
        >
          Subscribe
        </v-btn>
      </div>

      <v-alert
        v-model="state.success"
        type="success"
        density="comfortable"
        dismissible
        transition="slide-y-transition"
      >
        Thank you for subscribing!
      </v-alert>
      <v-alert
        v-model="state.error"
        type="error"
        density="comfortable"
        dismissible
        transition="slide-y-transition"
      >
        {{ state.errorMsg }}
      </v-alert>
    </v-form>
  </div>
</template>

<style scoped>
  .form {
    display: flex;
  }
  .form .v-input {
    margin-right: 15px;
  }
  @media (max-width: 420px) {
    .form {
      display: block;
    }
    .form .v-input {
      margin-right: 0px;
    }
    .form .v-btn {
      width: 100%;
    }
  }
</style>
